@import 'shared/styles/app';
@import 'Button.mixins';

$button_widths: (
  1: 100px,
  2: 150px,
  3: 200px,
  4: 250px,
  5: 300px,
  6: 350px,
  7: 400px
);

.button {
  position: relative;
  z-index: 0;

  button {
    @include buttonBaseStyles;
    color: var(--black);

    @include buttonDisabledStyles;
  }

  &--no-border {
    border: none !important;
  }

  &-variation {
    &-primary {
      button {
        color: var(--white);

        @include buttonDisabledStyles;
      }
    }

    &-secondary {
      button {
        color: var(--black);

        &:not(:disabled):hover {
          background-color: transparent;
        }

        @include buttonDisabledStyles;
      }
    }
  }

  &-width {
    justify-content: center;

    button {
      width: 100%;
    }

    @for $i from 1 through 7 {
      &-#{$i} {
        width: nth($button_widths, $i);
      }
    }

    &-half {
      width: $half_button_width;
    }

    &-full {
      width: $full_button_width;
    }
  }

  &-icon {
    button {
      border: none !important;
      &::before {
        content: none;
      }
    }

    &-top {
      button {
        display: inline-flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: var(--black);

        & > * {
          flex: 100%;
          height: 32px;
          margin-bottom: 5px;
        }

        svg {
          height: 100%;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }

    &-left {
      button {
        display: flex;
        column-gap: 10px;
      }
    }
  }
}
