@import 'shared/styles/app';

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1%;
}
