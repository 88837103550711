@import 'shared/styles/app';

.date-square {
  &__wrapper {
    position: relative;
    height: calc(100% - 39.5px) !important;
  }

  &__date {
    box-shadow: none !important;
  }

  &__title {
    font-size: 18px;
    margin-bottom: 15px;
  }

  &__month {
    background-color: transparent !important;
    font-size: 28px;
    color: var(--white);
    padding: 20px;
  }

  &__day {
    font-size: 96px;
    font-weight: bold;
    line-height: 130px;
    padding: 6px;
  }
}
