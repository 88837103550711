@import 'shared/styles/app';

.multiplechoice-questions-markup {
  &__option {
    all: unset;
    box-sizing: border-box;
    grid-column-start: span 2;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: inline-flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: transparent !important;
    box-shadow: none !important;

    // This is a port from the previous logic from the `MultipleChoice` component styles:
    //
    // ```scss
    // &__slider-container {
    //   grid-column: 1 / -1;
    //
    //   .multiplechoice__option {
    //     height: calc(100% - 20px);
    //   }
    // }
    // ```
    div[class^='MultipleChoice_multiplechoice__slider-container__'] & {
      height: calc(100% - 20px);
    }
 
    &--with-image {
      justify-content: flex-start;
    }

    &--vertical {
      min-height: 60px;
      justify-content: center;

      &--with-image {
        display: grid !important;
        grid-template-columns: 50% 50%;
        height: 120px;
        padding: 10px 10px 20px !important;
      }

      &__image {
      display: flex;
      height: 100%;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      }
    }

    &--selected {
      position: relative;
      color: var(--white) !important;

      &__text {
        color: var(--white) !important;
      }
    }

    &__icon {
      position: absolute;
      top: -15px;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 0);
    }

    &__image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 88%;
      padding: 0.5em 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: 30vh;
      }

      &__text {
        align-self: flex-end;
      }
    }

    &--disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    &--vertical__image {
      display: flex;
      height: 100%;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__text {
      margin: auto;
      font-size: 18px;
      text-align: center;
      color: inherit;

      &--small {
        width: 100%;
        font-size: 14px;
      }
    }

    &.option-style {
      position: relative;
      z-index: 0;
      margin-top: 24px;
    }
  }

  .paper-style {
    padding: 10px;
  }
}
