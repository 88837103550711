@import 'shared/styles/app';

.grogdiary {
  text-align: center;
  max-width: calc(100vw - 150px);
  margin: auto;
  position: relative;
  height: 100%;

  &__list-wrapper {
    width: 100%;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__date {
    text-align: left;
    display: flex;
    padding: 10px 0;
    margin-bottom: 5px;
  }

  &__type {
    &-title {
      display: table;
      border-bottom: 2px solid var(--mine-shaft);
      padding-bottom: 5px;
      padding-right: 5px;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;

    background: linear-gradient(
      to bottom,
      rgba(var(--theme-primary-colour), 0),
      rgba(var(--theme-primary-colour), 1)
    );
  }

  &__list {
    margin-right: 20px;

    &-item {
      display: flex;
      align-items: center;
      border-bottom: 2px solid var(--mine-shaft);
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 40px;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 85%;
        margin-left: 10px;

        :global {
          button.MuiButtonBase-root.Mui-disabled {
            opacity: 0.2;
          }
        }
      }

      &-info {
        width: 15%;
        text-align: left;
      }

      &-drink {
        width: 100%;
        display: flex;
        place-content: flex-end flex-start;
        flex-wrap: nowrap;
        overflow: hidden;
        position: relative;

        :global {
          div[class^='Carousel_carousel__'] {
            width: 100%;
          }
        }
      }
    }
  }
}
