@import 'shared/styles/app';

.autocomplete {
  &-inputWrapper {
    padding-top: 100px;
    padding-bottom: 50px;
    width: 35%;
  }

  &-option {
    padding: 6px 16px;
    text-align: left;
    cursor: pointer;

    &:focus {
      background-color: aqua !important;
    }
  }

  :global {
    .MuiInputBase-input {
      font-family: $baseFontFamily;
      font-weight: $baseFontWeight;
    }

    em {
      font-style: normal;
    }

    .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
      top: -2px !important;
      border: 3px solid var(--black);
      border-radius: 4px 4px 12px 7px 9px 13px !important;
    }

    .MuiAutocomplete-paper {
      font-family: $baseFontFamily;
      background-color: var(--spring-wood) !important;
    }

    .MuiAutocomplete-root {
      .MuiOutlinedInput-input {
        padding: 10.5px 6px;

        &::placeholder {
          color: var(--black);
          opacity: 1;
        }
      }
    }
  }
}

.input {
  label {
    pointer-events: none;
    position: absolute;
    opacity: 0;
  }

  textarea,
  input {
    resize: none;
    position: relative;
    width: 100%;
    height: 100% !important;
    padding: 15px;
    font-family: $baseFontFamily;
    font-size: 18px;
    border: none !important;
    z-index: 999;
    background-color: transparent !important;

    &:focus-visible {
      outline: none;
    }
  }
}

.error {
  text-align: left;
  padding-left: 5px;
  margin-bottom: 5px;
}
