@import 'shared/styles/app';

.summary {
  &__button {
    $button-width: 70px;

    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    &--up {
      margin-top: -$button-width;
    }

    // Styles for down chevron can be added her
    // &__down {}
  }
}
