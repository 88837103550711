@import 'shared/styles/app';

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20%;
  background: #392d4b;
  justify-content: space-between;

  .button {
    &-close {
      padding: 20px 40px;
      background: none;
      border: none;
    }

    &-general {
      padding: 20px 40px;
      border: none;
      color: white;
      background: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      justify-content: center;
    }
  }
  &-modal {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    box-shadow: 24;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
