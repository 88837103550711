@import 'shared/styles/app';

.postcode {
  &__numbers {
    display: grid;
    grid-gap: 10px 20px;
    justify-content: center;
    font-weight: bold;
    font-size: 28px;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent !important;

    & > div {
      margin-top: 6px !important;
      color: var(--mine-shaft) !important;
    }
  }

  &__del {
    grid-column: span 2;
    display: flex;
    align-items: center;

    button {
      height: 55px;
    }
  }

  &__idk {
    grid-column: span 3;
  }
}
