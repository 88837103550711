@import 'shared/styles/app';

.header {
  background: var(--white);

  .headerRowOne {
    display: flex;
    justify-content: center;
    padding: 12px 18px 7px 10px;

    .header-left,
    .header-right {
      flex: 50%;
      display: inline-flex;
      align-items: center;
    }

    .header-right {
      justify-content: flex-end;

      &-icons {
        &--volume {
          width: 100px;
        }
      }
    }

    .select {
      min-width: 250px;
      max-width: 100%;
    }
  }

  .headerRowTwo {
    width: 100%;

    .progress {
      height: 10px;
    }
  }
}

.helpModal {
  &-content {
    margin-top: 50px;
    min-width: 400px;
    height: 100%;
  }
}
