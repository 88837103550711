@import 'shared/styles/app';
@import 'components/Slider/Slider.mixins';

.grog-slider {
  @include sliderStyles;

  &__wrapper {
    text-align: center;
  }

  &__dynamic {
    &--vertical {
      position: relative;
      margin-top: -2vh;

      &-wrapper {
        position: relative;
      }

      &-mask {
        position: absolute;
        inset: 0;
        mask-size: contain;
        opacity: 0.8;
      }
    }

    &--horizontal {
      max-width: 80%;
      margin: auto;
      margin-top: 15%;
      padding-top: 0%;

      &-wrapper {
        display: flex;
        flex-wrap: wrap;
        min-height: 300px;
        max-height: 400px;
        align-items: center;
      }

      &-wrapper > * {
        flex: 1 1 12%;
      }

      &-item {
        max-height: 120px;
        flex: 0 1 calc(10% - 4px);

        img {
          max-height: 120px;
        }

        &:nth-child(5n) {
          margin-right: 40px;
        }

        &:nth-child(15n) {
          margin-right: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-button {
      margin-left: 60px;
      margin-right: -220px;

      button {
        padding: 18px;
      }
    }

    &-icon {
      position: relative;
      top: 22px;
      z-index: 1;

      &--fizzy {
        transform: rotate(45deg);
      }
    }
  }

  &__inner-wrapper {
    &-horizontal {
      position: relative;
      height: calc(70% - 15px);
      display: flex;
      align-items: flex-end;
      width: 100%;
      justify-content: center;
      margin-top: -5vh;
    }

    &-vertical {
      position: relative;
      height: calc(65% - 15px);
      display: flex;
      align-items: flex-end;
      width: 100%;
      justify-content: center;
      margin-top: -5vh;
      top: 9vh;
    }
  }

  &__optional-section {
    margin-top: 15px;
  }
}

.sliderAlign {
  &--middle {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  &--inline {
    display: inline-grid;
    grid-template-columns: auto auto;
    column-gap: 25px;
    align-items: flex-start;

    :global(.image-space-holder) {
      margin-top: 0;
    }

    .slider {
      margin-top: 0 !important;
    }

    & > * {
      flex: 1 auto;
      margin-top: 50px;
    }
  }

  .slider {
    margin-top: 25%;

    :global {
      .MuiSlider-track {
        border: none;
      }

      .MuiSlider-mark {
        display: none;
      }

      .MuiSlider-rail {
        color: rgb(51 51 51 / 20%);
      }

      .MuiSlider-thumb {
        height: 50px;
        width: 50px;
        border: none;
        box-shadow: 0 6px 0 rgb(51 51 51 / 20%);
      }
    }
  }
}
