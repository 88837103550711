@import 'shared/styles/app';

.progressscreen {
  background-color: var(--clam-shell);
  width: 100%;
  height: 100%;
  display: flex;

  &-content {
    width: 100%;
    padding: 30px;

    p {
      font-weight: bold;
      margin-bottom: 12px;
      font-size: 18px;
    }
  }

  &-row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;

    &-form {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;

      :global(.MuiSelect-select) {
        font-size: 18px;
        font-weight: bold;
        padding: 20px;
      }
    }

    &-syncButton {
      margin-left: 100px;

      button {
        color: var(--black);
        padding: 15px;
        min-width: 125px;
      }

      &--loading {
        svg[class^='Icon_icon__'] {
          animation: spin-animation 1s infinite;
        }
      }
    }
  }

  &-row2 {
    position: relative;
    height: calc(100% - 100px);
    margin-top: 15px;

    :global(div[class^='Card_title__']) {
      padding: 15px 0;

      h2 {
        font-size: 18px;
      }
    }
  }

  &-card {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: calc(100% - 30px);
    z-index: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    overflow: visible;

    :global {
      .MuiCardContent-root {
        height: 100%;
      }

      div[class^='Card_children__'] {
        height: calc(100% - 100px);
        overflow: auto;
      }
    }
  }

  &-analysis {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;

    &-section {
      &-wrapper {
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 17px;
      }

      &-labels {
        display: flex;
        justify-content: space-between;
      }
    }

    &-progress {
      margin-top: 7px;
      border: none;
      border-radius: 10px;
    }
  }

  &-analysisWrapper {
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 35px;

    &--total {
      display: block;
      font-size: 18px;

      .progressscreen-analysis {
        &-section {
          &-wrapper {
            display: block;
            margin-bottom: 30px;
          }

          &-labels {
            align-items: center;

            &-big {
              font-size: 28px;

              span {
                margin-left: 50px;
              }
            }
          }
        }
      }
    }
  }
}
