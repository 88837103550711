@import 'shared/styles/app';

.feedback {
  &-container {
    padding: 30px;

    p,
    label {
      font-size: 18px !important;
      font-weight: 800;
      color: var(--mine-shaft);
    }
  }

  &-heading {
    text-align: center;
    margin-bottom: 30px;
  }

  &-selectOptions {
    width: 100%;
    margin: 30px 0;
    display: flex;
    align-items: center;

    p {
      text-align: left;
    }

    & > div:last-of-type {
      width: 100%;
    }
  }

  &-selectLabels {
    width: 30%;
  }

  &-dropdown--half {
    width: 50%;
    float: left;
  }

  &-dropdown--full {
    width: 100%;
  }

  &-section {
    margin: 30px 0;

    p {
      text-align: left;
    }
  }

  &-questions {
    margin-left: 20px;
    margin-top: 40px;

    p {
      text-align: left;
    }
  }

  &-options {
    max-width: 100% !important;
    margin-top: 40px !important;
  }

  &-numberInput {
    padding: 5px;
    margin: 10px !important;

    input {
      border-radius: 4px;
      border: 3px solid var(--mine-shaft);
    }
  }

  &-inputOption {
    display: flex;
    align-items: center;
    margin-left: 20px;

    & > div:first-child {
      width: 50%;
    }
  }

  &-otherComments {
    margin: 20px 0;
  }

  &-4c {
    display: flex;
  }

  &__icon--dimmer {
    & ~ label {
      opacity: 0.5;
    }
  }
}

.margin-small {
  margin-bottom: 10px;
}
