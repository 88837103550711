@import 'shared/styles/app';

.carousel {
  height: 100%;
  
  $slide-height: auto;
  $slide-spacing: 10px;
  $slide-size: auto;
  display: flex;
  flex-direction: row;

  &__slides {
    margin: auto;
    display: flex !important;
    align-items: center;
    flex-direction: row;
  }

  &__viewport {
    overflow: visible;
    width: 85vw;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  &__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc($slide-spacing * -1);

    &--centred {
      justify-content: center;
    }
  }

  &__slide {
    transform: translate3d(0, 0, 0);
    min-width: 0;
    padding-left: $slide-spacing;
    transition: all ease 0.3s;
    height: auto;

    & > div {
      height: 100%;

      & > div {
        position: relative;
        height: 100%;
      }
    }

    & > div > div {
      height: 100%;

      & > div {
        position: absolute;
        height: 100%;
      }
    }
  }

  &__buttons {
    touch-action: manipulation;
    cursor: pointer;
    z-index: 999;
  }

  &__buttons:disabled {
    opacity: 0.3;
  }

  :global {
    .MuiIconButton-root {
      display: inline-block;
      z-index: 1;
      padding-top: 8em;
      padding-bottom: 8em;
    }
  }
}
