@import 'shared/styles/app';

.pinscreen {
  &-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    :global(.MuiCard-root) {
      position: relative;
      top: unset;
      left: unset;
      margin-right: 0;
      transform: none;
      width: 100%;
      max-width: 100%;
    }
  }

  &-demo-button-wrapper {
    margin-top: 60px;
  }

  &-demo-button {
    width: 276px;

    button {
      padding: 15px 50px;
    }
  }

  &-alert {
    margin-bottom: 20px;
  }

  &-info {
    max-width: 345px;
    padding: 0 30px;
  }

  &-text {
    margin-bottom: 25px;
  }

  &-re-cache-checkbox {
    margin-top: 30px;
    margin-bottom: -15px;
  }
}
