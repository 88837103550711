@import 'shared/styles/app';

.summary-drink-listing {
  &__item {
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--mine-shaft);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 40px;
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 85%;
      margin-left: 10px;

      :global {
        button.MuiButtonBase-root.Mui-disabled {
          opacity: 0.2;
        }
      }
    }

    &__grid {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &__container {
      flex: 0 0 10%;
      width: 0;
    }

    &__info {
      width: 15%;
      text-align: left;
    }

    &__drink {
      position: relative;
      width: 100%;
      display: flex;
    }
  }
}
