@import 'shared/styles/app';

.declinedscreen {
  background-color: var(--gray-suit);
  height: 100%;

  &-wrapper {
    height: 100%;
    padding: 115px 0;
    text-align: center;

    p {
      font-size: $baseFontSizeTitle;
      font-weight: $baseFontWeightLight;
      margin-bottom: 23px;
    }

    & > div {
      height: calc(100% - 115px);
    }
  }

  &-qs {
    &--q3 {
      &-options {
        div[class^='MultipleChoice_multiplechoice__wrapper__'] {
          top: 85px;
          justify-content: flex-start;
        }
      }
    }

    &--finish {
      position: relative;

      h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-footer {
    position: absolute;
    bottom: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
