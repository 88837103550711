@import 'shared/styles/app';

.communitylocationsscreen {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  &-titles {
    text-align: center;
    margin-bottom: 100px;
  }
}
