@import 'variables/breakpoints';
@import './fonts';

$baseLineHeight: 1.5;
$baseLetterSpacing: 0.0125em;
$baseParagraphSpacing: 15px;
$baseFontSize: 14px;
$baseFontSizeSubtitle: 18px;
$baseFontSizeParagraph: 16px;
$baseFontSizeTitle: 18px;
$baseFontFamily: 'Manrope', sans-serif;
$baseFontWeight: bold;
$baseFontWeightLight: normal;

@mixin header {
  font-family: $baseFontFamily;
  line-height: $baseLineHeight;
  font-weight: $baseFontWeight;
  letter-spacing: $baseLetterSpacing;
}

h1, h2, h3, h4 {
  @include header;
}

h1 {
  font-size: $baseFontSize*2.5;

  @include smMax {
    font-size: $baseFontSize*1.875;
  }

  &.screen-titles--title {
    font-size: 28px;
  }
}

h2 {
  font-size: $baseFontSize*2;

  @include smMax {
    font-size: $baseFontSize*1.5;
  }
}

h3 {
  font-size: $baseFontSize*1.5;

  @include smMax {
    font-size: $baseFontSize*1.25;
  }
}

h4 {
  font-size: $baseFontSize*1.25;
}

p {
  font-size: $baseFontSize;
}
