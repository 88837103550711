@import 'shared/styles/app';

.addmultidate {
  text-align: center;
  max-width: 1000px;
  margin: auto;

  &__title {
    margin-bottom: 30px;
  }

  &__subtitle {
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__slide {
    min-height: 225px;
    display: flex !important;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    padding: 3px;
    background-color: transparent !important;
    position: relative !important;

    &--date {
      box-shadow: none !important;
    }
  }

  &--disabled {
    opacity: 0.3;
  }

  &__adddate {
    position: relative;
    top: 39.5px;
    justify-content: center;
    align-items: center;
    height: calc(100% - 39.5px) !important;
    cursor: pointer;
  }
}
