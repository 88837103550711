@import 'src/shared/styles/app';

.circlechoice-item {
  &__option {
    position: relative;
    width: 133px !important;
    height: 133px !important;

    &--dimmer {
      opacity: 0.5;
    }
  }
}
