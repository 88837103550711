@import 'shared/styles/app';

.initialisation-screen {
  background-color: var(--clam-shell);
  width: 100%;
  height: 100%;

  &-alert {
    margin-bottom: 20px;
  }

  &-main-header {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
  }

  :global(.MuiCard-root) {
    background-color: transparent;
  }
}
