@import 'shared/styles/app';

.circlechoice {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 60vh;
  text-align: center;

  &__title,
  &__subtitle {
    margin: 0 70px;
    text-align: center;
  }

  &__content {
    position: absolute;
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 25px;
    max-width: calc(100vw - 20px);
    margin: 20px auto auto auto;
  }

  &__optionText {
    height: 42px;
    margin-bottom: 1em;
  }

  &__wrapper {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  :global {
    .MuiIconButton-root {
      padding-top: 1em !important;
      padding-bottom: 1em !important;
    }
  }
}
