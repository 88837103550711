// Font size for the header title
$header-title-font-size: 2em;
$text-font-size: min(2vw, 16px);
$input-font-size: 1em;
$button-font-size: 1em;

// Width for buttons
$xs_button_width: 110px;
$s_button_width: 135px;
$m_button_width: 160px;
$l_button_width: 190px;
$xl_button_width: 220px;
$xxl_button_width: 280px;
$half_button_width: 50%;
$full_button_width: 100%;

// Padding for input fields
$input_padding: 10px;

// Margin for various elements
$margin: 9px;

// Maximum size for small (sm) screens
$smMax: calc(0.9rem + 0.5vw);
