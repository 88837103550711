@import 'shared/styles/app';

.summary-list {
  margin-right: 20px;

  &__container {
    height: 42vh;
    max-height: calc(100% - 80px);
    overflow-y: auto;
    width: calc(100% + 20px);

    background: radial-gradient(
      circle at center,
      rgba(var(--pot-pourri), 1) 0%,
      rgba(var(--bone), 1) 100%
    );
  }

  &__type-title {
    display: table;
    border-bottom: 2px solid var(--mine-shaft);
    padding-bottom: 5px;
    padding-right: 5px;
  }
}
