@import "shared/styles/app";

.supportCard {
  &-container {
    width: 470px;
    height: 150px;
    position: relative;
    z-index: 0;
  }

  &-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    overflow: hidden;
    color: var(--white);
  }

  &-image {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin-right: 15px;
    flex-shrink: 0;
    position: relative;
  }

  &-name {
    font-weight: 800;
    font-size: 18px;
  }

  &-info {
    margin: 10px 0;
    font-weight: 500;
    font-size: 15px;

    a {
      display: flex;
      gap: 15px;

      &,
      &:link,
      &:visited,
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}