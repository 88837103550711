@import 'shared/styles/app';

.input {
  label {
    pointer-events: none;
    position: absolute;
    opacity: 0;
  }

  textarea,
  input {
    resize: none;
    position: relative;
    width: 100%;
    height: 100% !important;
    padding: 15px;
    font-family: $baseFontFamily;
    font-size: 18px;
    border: none !important;
    z-index: 999;
    background-color: transparent !important;

    &:focus-visible {
      outline: none;
    }
  }
}

.error {
  text-align: left;
  padding-left: 5px;
  margin-bottom: 5px;
}
