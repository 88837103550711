@import 'shared/styles/app';

.follow-up-body-images {
  &__image {
    &--circle {
      all: unset;
      cursor: pointer;
      position: absolute;
      width: 16dvh;
      height: 16dvh;

      img {
        width: 100%;
        height: 100%;
      }

      &--wiggle {
        animation: wiggle 2s infinite;
      }
    }

    &--selected {
      border: 2px var(--white) solid;
      border-radius: 50%;
      animation: pulse 1s infinite;
    }

    &--brain {
      top: 1dvw;
    }
    &--mood {
      top: 12dvh;
      left: 2dvw;
    }
    &--liver {
      top: 30dvh;
      left: 2dvw;
    }
    &--kidney {
      top: 48dvh;
      left: 2dvw;
    }
    &--fertility {
      top: 66dvh;
      left: 2dvw;
    }
    &--teeth {
      top: 12dvh;
      right: 2dvw;
    }
    &--heart {
      top: 30dvh;
      right: 2dvw;
    }
    &--stomach {
      top: 48dvh;
      right: 2dvw;
    }
    &--limbs {
      top: 66dvh;
      right: 2dvw;
    }
  }
}
