@import 'shared/styles/app';

.alcoholriskscreen {
  position: relative;
  height: 80vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;

  &-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &-textWrapper {
    width: 50%;
    display: flex;
    padding: 0 20px;
    flex-direction: column;
    justify-content: center;

    br {
      display: block;
      content: '';
      margin-top: 30px;
    }
  }

  &-image {
    width: 150%;
    height: auto;
    display: block;
    margin-left: -10vw;
  }
}
