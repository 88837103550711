@import 'shared/styles/app';

.product {
  &-wrapper {
    position: relative;
  }

  &-img,
  &-background {
    left: 0;
    bottom: 0;
    object-fit: contain;
    max-height: 200px;
  }

  &-img {
    object-position: bottom;
    display: block;
  }

  &-background {
    position: absolute;
    opacity: 0.2;
    display: block;
    width: 100%;
  }
}
