@import 'shared/styles/app';

:root {
  // Default value for iconHeight
  --standard-container-height: 80px;

  // Maximum number of icons in each row
  --max-icons-per-row: 15;
}

.grog-image {
  &-wrapper {
    position: relative;
  }

  &-img,
  &-background {
    left: 0;
    bottom: 0;
    object-fit: contain;
    max-height: 200px;
  }

  &-img {
    object-position: bottom;
  }

  &-background {
    position: absolute;
    opacity: 0.2;
    display: block;
    width: 100%;
  }

  &__dynamic {
    &--horizontal {
      max-width: 100%;
      margin: auto;
      margin-top: 5%;

      &-wrapper {
        position: absolute;
        top: 15vh;
        left: 50%;
        transform: translateX(-50%);
        display: grid;
        grid-template-columns: repeat(var(--max-icons-per-row), 1fr);
        padding: 10px;

        &-share {
          top: 22vh;
        }
      }

      &-item,
      &-wrapper > * {
        display: grid;
        grid-template-columns: repeat(var(--max-icons-per-row), 1fr);
        max-height: var(--standard-container-height);

        /* Removes unnecessary left margin */
        margin-left: -2.8vw;
        margin-right: -2.8vw;

        /* Maintain aspect ratio */
        img {
          max-height: 120px;
          width: auto;
        }

        @media only screen and (max-width: 1024px) {
          img {
            max-height: 120px;
            max-width: 100px;
          }
        }

        &:nth-child(5n) {
          gap: 0.5vw;
        }

        // No gap at the end of each row
        &:nth-child(15n) {
          gap: 0vw;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__inner-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: calc(100% - 15px);
    width: 100%;
  }

  &__wrapper {
    text-align: center;
  }
}
