@import 'shared/styles/app';

$button-margin: 10px;

.optionalbuttons {
  position: fixed;
  top: 78vh;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
    align-items: center;
  }

  .gridItem {
    // Force all buttons to be in the same row
    // (used when there are two buttons and one is in the corner)
    grid-row: 1;
  }

  .button {
    margin-right: #{$button-margin}px;
    margin-left: #{$button-margin}px;
    color: inherit;
    background-color: inherit;
    width: 100%;

    &.selected {
      color: var(--white) !important;
      background-color: var(--tundora) !important;
    }

    &-one-button {
      &-corner {
        top: 8vh;
        left: 37vw;
      }

      &-default {
        top: 0vh;
      }
    }

    &-two-buttons {
      &-one-corner {
        top: 8vh;
        left: 37vw;
      }

      &-two-corner {
        top: 8vh;
        left: 30vw;
      }

      &-default {
        top: 0vh;
      }
    }
  }
}
