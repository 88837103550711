@import 'shared/styles/app';

.dashboardscreen {
  background-color: var(--clam-shell);
  width: 100%;
  height: 100%;
  display: flex;

  &-content {
    width: 100%;
    padding: 16px 30px;

    p,
    h1 {
      font-weight: bold;
      margin-bottom: 12px;
      font-size: 18px;
    }
  }

  &-row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;

    &-form {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;

      :global(.MuiSelect-select) {
        font-size: 18px;
        font-weight: bold;
        padding: 20px;
      }
    }

    &-syncButton {
      margin-left: 10px;

      @media (max-width: 600px) {
        // Remove margin for iPad Mini
        margin-left: 0;
      }

      button {
        color: var(--black);
        padding: 15px;
        min-width: 125px;
      }

      &--loading {
        svg[class^='Icon_icon__'] {
          animation: spin-animation 1s infinite;
        }
      }
    }
  }

  &-row2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 25px;

    &-card {
      font-weight: bold;
      cursor: pointer;

      button {
        padding: 32px 0;
        font-size: 18px;
        height: 100%;
        gap: 15px;

        svg {
          width: 35px;
          height: 45px;
          margin-bottom: 12px;
        }
      }
    }
  }

  &-row3 {
    position: relative;
    height: calc(100% - 310px);
    margin-top: 15px;

    :global(div[class^='Card_title__']) {
      padding: 15px 0;

      h2 {
        font-size: 18px;
      }
    }
  }

  &-card {
    width: 100%;
    position: relative;
    bottom: 0;
    height: calc(100% - 30px);
    z-index: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    overflow: visible;

    :global {
      .MuiCardContent-root {
        height: 100%;
      }

      div[class^='Card_children__'] {
        height: calc(100% - 100px);
        overflow: auto;
      }
    }
  }

  &-analysisWrapper {
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 35px;
  }

  &-newSurveyModal {
    &-content {
      padding: 40px 60px;

      &-info {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px;

        p {
          font-weight: $baseFontWeight;
          width: 135px;
          margin-left: 20px;
          font-size: 18px;
          text-align: left;
        }
      }

      &-form {
        display: flex;
        flex-flow: column wrap;

        &-select {
          flex: 1;
          margin-top: 14px !important;

          :global(.MuiInputBase-input) {
            font-weight: $baseFontWeight;
          }
        }

        &-startButton {
          margin-top: 15px;

          button {
            color: var(--black);
            padding: 30px 0;
          }
        }

        &-cancelButton {
          margin-top: 10px;

          button {
            color: var(--black);
            padding: 15px 0;
          }
        }
      }
    }

    :global(.MuiDialogActions-root) {
      display: none;
    }
  }
}

.dailyFeedbackModal-cta {
  margin-bottom: 20px;
}
