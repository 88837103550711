@import 'shared/styles/app';

.projectselectorscreen {
  background-color: var(--clam-shell);
  width: 100%;
  height: 100%;

  &-select {
    display: grid;
    row-gap: 24px;

    :global(.MuiInputBase-input),
    li {
      font-weight: $baseFontWeight;
    }
  }
}
