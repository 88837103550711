@import 'shared/styles/app';

.multiplechoiceimagescreen {
  position: relative;
  margin-bottom: 0;

  div[class^='MultipleChoice_multiplechoice__wrapper__'] {
    position: unset;
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10vh;

    h1 {
      text-align: center;
      margin: 0;
    }
  }

  &-subtitle {
    text-align: center;
    margin-top: -8vh;
    margin-bottom: 7vh;

    &--hasOptions {
      padding-top: 0;
    }
  }

  // Applies only if there is a subtitle and title
  &-title + &-subtitle {
    &-title {
      margin-bottom: 0;
    }
  }

  &-choices {
    position: relative;
    bottom: 2px;
    width: 100%;
    height: calc(100% - 170px);
    text-align: center;
  }

  &-buttons {
    margin-left: 15px;
  }

  &-button {
    position: relative;
    z-index: 10;

    &:global(.MuiToggleButton-root) {
      min-width: 115px;
      min-height: 55px;
      color: var(--mine-shaft);
      text-transform: none;
      font-weight: $baseFontWeight;
      font-size: $baseFontSizeTitle;
      border: none;
    }

    &:global(.MuiToggleButton-root.Mui-selected) {
      color: var(--white);
    }
  }
}
