$screen-xl-min: 1367px;
$screen-xl-max: 1366px;
$screen-lg-min: 1140px;
$screen-lg-max: 1139px;
$screen-md-min: 980px;
$screen-md-max: 979px;
$screen-sm-min: 600px;
$screen-sm-max: 599px;
$screen-xs-min: 320px;
$screen-xs-max: 319px;

// Extra Small devices
@mixin xs {
  @media only screen and (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin xsOnly {
  @media only screen and (min-width: #{$screen-xs-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin xsMax {
  @media only screen and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media only screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin smOnly {
  @media only screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin smMax {
  @media only screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media only screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin mdOnly {
  @media only screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin mdMax {
  @media only screen and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media only screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin lgOnly {
  @media only screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin lgMax {
  @media only screen and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media only screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}
