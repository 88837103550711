@import 'shared/styles/app';

.using-amount-markup {
  &__titles {
    text-align: center;
  }

  &__amount {
    max-height: 50%;
  }
}
