@mixin buttonBaseStyles {
  font-family: $baseFontFamily;
  font-size: $button-font-size; // Scale
  font-weight: bold;
  padding: 17px;
  white-space: wrap; // Prevent text from wrapping to a new line
  overflow: hidden; // Keep the overflowed content hidden
  text-overflow: ellipsis; // Display an ellipsis for overflowed content
}

@mixin buttonDisabledStyles {
  &:global(.MuiButtonBase-root.Mui-disabled) {
    opacity: .7;
  }
}