@import 'shared/styles/app';

.text-title {
  margin-bottom: $margin;
  font-size: $header-title-font-size;

  width: 100%;

  // Base size and scaling down on smaller screens
  @include smMax {
    font-size: $smMax;
  }
}

.text-field input {
  padding: $input_padding;
  font-family: $baseFontFamily;
  font-size: $input-font-size;

  // Adjusting for smaller screens
  @include smMax {
    font-size: $smMax;
  }
}

.text {
  font-size: $text-font-size;
  &-title {
    margin-bottom: $margin;
  }

  &-field {
    width: 100%;

    input {
      padding: $input_padding;
      font-family: $baseFontFamily;
    }

    fieldset {
      border: none !important;
    }
  }
}
