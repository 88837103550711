@import 'shared/styles/app';

.followupbody {
  display: relative;
  width: 100%;
  height: 100%;
  min-height: 650px;
  max-height: 750px;
  text-align: center;
  overflow: hidden;

  .leftColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 4dvw;
    margin-right: -4dvw;
    width: 33dvw;
    height: 100%;

    .title,
    .subtitle {
      margin-bottom: 1rem;
      text-align: center;
    }

    .list {
      width: 100%;
      ul {
        padding-left: 2rem;
        text-align: left;
      }
    }
  }

  .rightColumn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 66dvw;

    .images {
      position: relative;
      width: 90%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding-bottom: 5vh;

      // Body diagram image
      &__body-diagram {
        height: 86dvh;
      }
    }
  }
}
