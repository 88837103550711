@import 'shared/styles/app';
@import 'components/Slider/Slider.mixins';

.drinkingcircleslider {
  @include sliderStyles;

  &-images {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;

    img {
      max-width: 100%;
      max-height: 350px;
      // transition: opacity 0.2s ease-in-out;
    }
  }

  &-innerWrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;

    :global(.MuiSlider-root) {
      max-width: 80vw;
    }
  }

  .sliderAlign {
    .slider {
      :global(.MuiSlider-root) {
        margin-top: 10px;
      }
      margin-top: 30px !important;
    }

    &--middle {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &--inline {
      display: inline-grid;
      grid-template-columns: auto auto;
      column-gap: 25px;
      align-items: flex-start;

      :global(.image-space-holder) {
        margin-top: 0;
      }

      .slider {
        :global(.MuiSlider-root) {
          margin-top: 10px;
        }
        margin-top: 0 !important;
      }

      & > * {
        flex: 1 auto;
      }
    }
  }
}
