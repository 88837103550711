@import 'shared/styles/app';

.contactsscreen {
  &-titles {
    text-align: center;
    margin-bottom: 20px;
  }

  &-container {
    display: flex;
    justify-content: space-around;
    margin: 0 50px;
    gap: 20px;
    flex-wrap: wrap;

    &-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: var(--cape-palliser);
      color: var(--white);
      border-radius: 12px;
      box-shadow: 0 4px 6px var(--black);
      padding: 20px;
      width: 100%;
      max-width: 400px;
      height: auto;
      text-align: center;

      p {
        margin-bottom: 15px;
      }
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-bottom: 10px;
    }

    &-contact {
      margin-top: 10px;
    }
  }

  @media (max-width: 768px) {
    &-container {
      margin: 0 20px;
      gap: 10px;

      &-column {
        padding: 10px;
        max-width: 100%;
      }
    }

    img {
      width: 40px;
      height: 40px;
    }
  }

  @media (max-width: 480px) {
    &-container {
      flex-direction: column;
      align-items: center;

      &-column {
        max-width: 90%;
        margin-bottom: 20px;
      }
    }
  }

  .cardContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: var(--tundora);
    padding: 10px 20px;
    gap: 12px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    p {
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .imageContainer {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px auto;
  }

  img {
    width: 100%;
    height: auto;
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    p {
      margin-bottom: 8px;
      text-align: center;
    }
  }

  .text,
  .bold {
    margin: 0;
    text-align: center;
  }

  .bold {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: bold;
  }
}
