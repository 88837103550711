@import 'shared/styles/app';

.supportscreen {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: var(--clam-shell);

  &-content {
    padding: 16px 30px;
  }

  &-cards {
    margin: 10px 0;
  }
}
