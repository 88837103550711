@import 'shared/styles/app';

$piechart-min-width: 385px;
$piechart-label-max-width: 100px;
$piechart-label-size: 28px;
$piechart-piece-opacity-complete: 0.1;
$piechart-piece-opacity-complete-four: 0.2;
$piechart-label-size-four: 14px;
$piechart-label-width-four: 140px;
$piechart-label-height-four: 55px;
$piechart-label-border-color-four: rgb(34 34 34 / 20%);
$piechart-label-offset-four: 47px;
$piechart-tick-offset-four: 30px;
$piechart-tick-offset-four-2: 80px;
$piechart-tick-offset-four-right: 185px;
$piechart-tick-offset-four-left: -25px;

.piechart {
  position: relative;
  top: 0;
  left: 0;
  min-width: $piechart-min-width;

  &--three & {
    &__content {
      display: inline-block;

      &__label {
        font-size: $piechart-label-size;
        max-width: $piechart-label-max-width;
        text-align: center;
        z-index: 98;

        &--0,
        &--1,
        &--2 {
          position: absolute;
        }

        &--0 {
          top: 117px;
          left: 51px;
        }

        &--1 {
          top: 117px;
          left: 230px;
        }

        &--2 {
          top: 275px;
          left: 150px;
        }
      }

      &__piece {
        &--complete {
          opacity: $piechart-piece-opacity-complete;
        }

        &--0,
        &--1,
        &--2 {
          position: absolute;
          cursor: pointer;
        }

        &--0 {
          top: 0;
          left: 0;
        }

        &--1 {
          top: 0;
          left: 183px;
        }

        &--2 {
          top: 193px;
          left: 29px;
        }
      }

      &__tick {
        position: absolute;
        z-index: 99;

        &--0 {
          top: 85px;
          left: -6px;
        }

        &--1 {
          top: 85px;
          left: 333px;
        }

        &--2 {
          top: 360px;
          left: 160px;
        }
      }
    }
  }

  &--four {
    padding: 10px 100px;
    display: inline-grid;
    width: 588px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0;
  }

  &--four & {
    &__content {
      margin-top: -5px;
      position: relative;

      &__label {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $piechart-label-size-four;
        padding: 10px;
        word-wrap: break-word; // Break words at the end of the line
        overflow-wrap: break-word; // Break non-space strings
        white-space: normal; // Allow text to wrap to the next line
        width: $piechart-label-width-four;
        height: $piechart-label-height-four;
        text-align: center;
        background-color: var(--white);
        border: 3px solid var(--black);
        z-index: 98;

        &--complete {
          border-color: $piechart-label-border-color-four;
        }

        &--0 {
          top: $piechart-label-offset-four;
          left: -$piechart-label-offset-four;
        }

        &--1 {
          top: $piechart-label-offset-four;
          right: -$piechart-label-offset-four;
        }

        &--2 {
          bottom: $piechart-label-offset-four;
          left: -$piechart-label-offset-four;
        }

        &--3 {
          bottom: $piechart-label-offset-four;
          right: -$piechart-label-offset-four;
        }
      }

      &__piece {
        &--complete {
          opacity: $piechart-piece-opacity-complete-four;
        }
      }

      &__tick {
        position: absolute;
        z-index: 99;

        &--0 {
          top: $piechart-tick-offset-four;
          left: $piechart-tick-offset-four-left;
        }

        &--1 {
          top: $piechart-tick-offset-four;
          left: $piechart-tick-offset-four-right;
        }

        &--2 {
          top: $piechart-tick-offset-four-2;
          left: $piechart-tick-offset-four-left;
        }

        &--3 {
          top: $piechart-tick-offset-four-2;
          left: $piechart-tick-offset-four-right;
        }
      }
    }
  }
}
