@import 'shared/styles/app';

.progress-analysis {
  &__section {
    &__wrapper {
      display: grid;
      grid-template-rows: 1fr 1fr;
      row-gap: 17px;
    }

    &__labels {
      display: flex;
      justify-content: space-between;
    }
  }

  &__progress {
    margin-top: 7px;
    border: none;
    border-radius: 10px;
  }
}
