@import 'shared/styles/app';

.profilescreen {
  background-color: var(--clam-shell);
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  display: flex;

  &-content {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    row-gap: 60px;

    &-avatar {
      position: relative;
      width: 220px;
      height: 220px;
      margin-left: auto;
      margin-right: auto;
    }

    &-info {
      position: relative;
      display: inline-block;

      &-content {
        padding: 30px 70px;
        text-align: left;
        display: grid;
        gap: 10px 50px;
        grid-template-columns: repeat(2, 1fr);

        h5 {
          font-size: 18px;
        }

        p {
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }
}
