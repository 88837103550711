@import 'shared/styles/app';

.section-item {
    // grid-column-start: span 2;
    position: relative;
    cursor: pointer;
    z-index: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--completed {
      opacity: 0.5;
      cursor: default;
    }

    &--disableClick {
      pointer-events: none;
    }

    &--dimmed {
      opacity: 0.5;
    }

    &-card {
      font-weight: bold;
      text-align: center;
      background-color: transparent !important;
      box-shadow: none !important;
      padding: 25px 15px 15px;
    }

    &-tickIcon {
      position: absolute;
      top: -15px;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 0);
    }

    &-content {
      display: flex;
      flex-wrap: wrap;

      & > * {
        flex: 100%;
      }
    }

    &-content-nowrap {
      display: flex;
      flex-wrap: nowrap;

      & > * {
        flex: 100%;
      }
    }

    &-icon {
      padding-top: 0.25em;

      img {
        width: var(--option-width);
      }
    }

    &-label {
      padding-bottom: 0.5em;
      font-size: larger;

      @include smMax {
        font-size: large;
      }
    }
}
