@import 'typography';

.screen-titles {
  &--title {
    font-weight: $baseFontWeight !important;
    text-align: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  &--subtitle {
    padding-top: 30px;
    font-size: $baseFontSizeSubtitle !important;
    font-weight: $baseFontWeightLight !important;
  }
}

// Margin
.no-margin {
  margin: 0 !important;
}

.mt {
  margin-top: 16px;
}

.mb {
  margin-bottom: 16px;
}

.ml {
  margin-left: 16px;
}

.mr {
  margin-right: 16px;
}

.mt-2x {
  margin-top: 32px;
}

.mb-2x {
  margin-bottom: 32px;
}

.ml-2x {
  margin-left: 32px;
}

.mr-2x {
  margin-right: 32px;
}

// Padding
.no-padding {
  padding: 0 !important;
}

.pt {
  padding-top: 16px;
}

.pb {
  padding-bottom: 16px;
}

.pl {
  padding-left: 16px;
}

.pr {
  padding-right: 16px;
}

.pt-2x {
  padding-top: 32px;
}

.pb-2x {
  padding-bottom: 32px;
}

.pl-2x {
  padding-left: 32px;
}

.pr-2x {
  padding-right: 32px;
}

// Align
.align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

// Container Border Style
.u-border-style-sketch {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  border: none !important;
  border-radius: 0 !important;
}

// Image Space holder
.image-space-holder {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  padding: 126px 0;
  border: 1px dashed;
  border-radius: 10px;
}
