@import 'shared/styles/app';

.container {
  &__mask {
    position: absolute;
    inset: 0;
    mask-size: contain;
    opacity: 0.8;
    mask-position: center;
    mask-repeat: no-repeat;
  }

  &__image {
    display: block;
    margin: auto;
  }

  &__wrapper {
    position: relative;
    text-align: center;
  }

  &__inner-wrapper {
    height: calc(100% - 15px);
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
  }
}
