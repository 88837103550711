@import 'shared/styles/app';

.modal-buttons {
  &__close-button {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 999;
  }
}
