@import 'shared/styles/app';
@import 'components/Slider/Slider.mixins';

.dynamicslider {
  @include sliderStyles;

  position: relative;
  text-align: center;
  max-width: 80%;
  height: 100%;
  margin: auto;

  .imageContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1%;
  }

  &-customComponent {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 2em;
    }

    img {
      max-width: 100%;
      max-height: 350px;
    }
  }

  &-staticImage {
    img {
      max-width: 100%;
      max-height: 500px;
    }
  }

  &-horizontal {
    max-width: 1000px;
    margin: auto;
    margin-top: 30px;

    &-wrapper {
      display: flex;

      img {
        max-width: 200px;
        margin-top: 50px;
      }
    }

    &-item {
      transition: all ease-in 0.3s;

      &:nth-child(5n) {
        margin-right: 30px;
      }

      &:last-child {
        margin-right: 0;
      }

      &--half:last-child {
        position: relative;
        display: inline-block;

        .opacityDiv {
          height: 100%;
          width: 50%;
          position: absolute;
          top: 0;
          right: 0;
          opacity: 0.8;
          z-index: 99;
        }
      }
    }
  }

  &-innerWrapper {
    height: 60%;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
    position: relative;

    :global(.MuiSlider-root) {
      max-width: 100%;
    }
  }

  .sliderAlign {
    &--middle {
      transform: translate(-50%, -50%);
    }

    &--inline {
      display: inline-grid;
      grid-template-columns: auto auto;
      column-gap: 250px;
      align-items: flex-start;

      :global(.image-space-holder) {
        margin-top: 10px;
      }

      .slider {
        margin-top: 0 !important;
      }

      & > * {
        flex: 10 auto;
      }
    }

    .slider {
      margin-top: 4%;

      :global {
        .MuiSlider-valueLabel {
          border-top-left-radius: 8px 9px;
          border-top-right-radius: 12px 8px;
          border-bottom-left-radius: 9px 13px;
          border-bottom-right-radius: 12px 7px;
        }
      }
    }
  }
}
