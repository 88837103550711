@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes wobble {
  33.3% {
    transform: translateX(-6px);
  }

  66.6% {
    transform: translateX(-3px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  33.3% {
    transform: rotate(-10deg);
  }

  66.6% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes figure-eight {
  0%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(2px, -2px) rotate(10deg);
  }
}

@keyframes wiggle {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}
