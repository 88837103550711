@import 'shared/styles/app';

.sidemenu {
  .activeItem {
    background-color: rgb(217 194 183 / 50%);
  }

  &-drawer {
    width: 175px;

    :global(.MuiDrawer-paper) {
      width: 175px;
      height: 100%;
      background-color: var(--cape-palliser);
      border: none;
    }
  }

  &-list {
    height: 100%;
    display: grid;
    grid-template-rows: repeat(6, 1fr);

    &:global(.MuiList-root) {
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }

  &-listItemButton {
    display: flex;
    flex-flow: column wrap;

    :global(.MuiDrawer-paper) {
      width: 170px;
      background-color: var(--cape-palliser);
    }

    :global(.MuiTypography-root) {
      font-family: $baseFontFamily;
    }

    &-icon {
      margin: auto;
    }

    &-text {
      color: var(--white);
    }
  }

  &-modal {
    :global(.MuiPaper-root) {
      padding: 80px 60px 40px;
    }

    &-buttons {
      display: flex;
      column-gap: 8px;
      align-items: center;
      justify-content: center;
    }
  }
}
