@import 'shared/styles/app';

.caching-progress-bar {
  margin-top: 10px;
  margin-bottom: 10px;

  progress  {
    border: 0;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    margin-top: 10px;
  }

  h3 {
    @extend h4;
  }
}
