@import 'shared/styles/app';

.landingscreen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cape-palliser);
}
