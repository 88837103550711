.snackbar {
  &__alert {
    width: 100%;

    &--success {
      background-color: var(--green-smoke);
      color: var(--white);
    }

    &--error {
      background-color: var(--crimson);
      color: var(--white);
    }
  }

}