@import 'shared/styles/app';

@mixin sliderStyles {
  position: relative;
  text-align: center;
  height: 100%;
  bottom: 0;
  margin: auto;
  max-width: 80%;

  h1 {
    margin: 0 90px;
  }

  &--noImage {
    :global {
      .MuiSlider-root {
        margin-top: -25vh;
      }
    }
  }

  .image {
    margin-top: 4vh;
    width: auto;
    height: 50vh;
    object-fit: cover;
  }

  .slider {
    &__inner-wrapper {
      height: 100%;
      display: flex;
      align-items: flex-end;
      width: 100%;
      justify-content: center;
    }
  }

  .sliderAlign {
    &--middle {
      position: absolute;
      width: 100%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 0);
    }

    &--noImage {
      margin: auto;
      width: 100%;
    }

    .slider {
      position: fixed;
      top: 75vh;
      width: 75%;
      margin-left: auto;
      margin-right: auto;

      :global {
        .MuiSlider-thumb {
          position: relative;
          height: 50px;
          width: 50px;
          border: none;
          box-shadow: 0 6px 0 rgb(51 51 51 / 20%);
          transition: transform 0.3s ease-in-out;

          &::before {
            display: none;
          }

          &:hover {
            transform: scale(1.1) translateY(-50%) translateX(-50%);
          }
        }

        .MuiSlider-track {
          border: none;
        }

        .MuiSlider-mark {
          display: none;
        }

        .MuiSlider-rail {
          color: rgb(51 51 51 / 20%);
        }

        .MuiSlider-valueLabel {
          font-family: $baseFontFamily;
          font-size: 18px;
          padding-top: 0;
          padding-bottom: 0;
          height: 40px;
          min-width: 120px;
          top: -20px;
          border-radius: 8px 9px 12px 8px 12px 7px 9px 13px;
          will-change: transform;
        }
      }

      &--round {
        :global {
          .MuiSlider-valueLabel {
            font-size: 28px;
            padding: 0;
            width: 57px;
            height: 57px;
            min-width: 0;
            top: -10px;
            border-radius: 50% 50% 50% 0;
            transform-origin: bottom left;
            transform: translate(50%, -100%) rotate(-45deg) scale(0);

            &::before {
              display: none;
            }

            &.MuiSlider-valueLabelOpen {
              transform: translate(50%, -100%) rotate(-45deg) scale(1);
            }

            & > * {
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}
