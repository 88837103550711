@import 'shared/styles/app';

.screen {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  
  background-color: var(--theme-primary-colour);

  .container {
    position: relative;
    width: 100%;
    height: calc(100vh - 180px);
    max-width: $containerMaxWidth;
    margin: auto;
    /* top | left and right | bottom */
    padding: 2em 0 1em;
  }
}
