@import 'shared/styles/app';

.loading-assets-screen {
  &-alert {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  &-modal {
    &-heading {
      margin-bottom: 5px;
    }

    p {
      font-size: 18px;
      margin-bottom: 4px;
    }
  }
}
