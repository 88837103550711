@import 'shared/styles/app';

.multiplechoice {
  position: relative;
  width: 100%;
  height: 100%;

  &__title,
  &__subtitle {
    /* top and bottom | left and right */
    margin: 0 70px;
    text-align: center;
  }

  &__body {
    height: 100%;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* top | left and right | bottom */
    padding: 0 2em 2em;
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 300px); // 100vh - (header+footer+title+subtitle)
    margin: auto;

    &--top-space {
      top: 125px;

      :global(.MuiPaper-root) {
        padding: 10px;
      }
    }

    &.wrapper-style {
      margin-top: -50px;
    }
  }

  &--side {
    .multiplechoice__body {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex-direction: column;
      width: calc(50% - 20px);
      height: 100%;

      h1 {
        margin: 0 24px;
      }

      .multiplechoice__wrapper {
        position: relative;
        height: auto;
        min-height: unset;
        margin: unset;
        bottom: unset;
      }
    }

    .multiplechoice__image {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: fixed;
      bottom: 0;
      right: 0;
      width: 50%;
      height: 100%;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }
  }

  &__container {
    grid-auto-rows: 1fr;
    grid-gap: 20px;

    &.box-style {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__slider-container {
    grid-column: 1 / -1;

    .multiplechoice__option {
      height: calc(100% - 20px);
    }
  }

  &__options {
    grid-column: 1 / -1;
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: 8px;
    font-size: $baseFontSizeTitle;

    & > div {
      grid-column: span 2;
    }
  }

  &__illustration {
    grid-column: 3 / 9;
    grid-row: 1 / -1;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 355px);

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    &--optional {
      grid-column: 3 / 9;
      grid-row: 1 / -1;
      overflow: hidden;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 430px);

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .paper-style {
    padding: 10px;
  }
}
