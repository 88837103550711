@import 'shared/styles/app';

.effectscarouselscreen {
  &-titles {
    text-align: center;
    font-size: 2vw; // 20px
    margin: 0;
    padding: 0;
  }

  &-container {
    height: 100vh;
    position: relative;
  }
}
