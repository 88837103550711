@import 'shared/styles/app';

.inner-modal {
  .icon-text-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .modal-container {
    width: 40%;
    height: 30%;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;

    &-content {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: space-evenly;

      .textarea {
        border: none;
        background: #d3d3d3;
        resize: none;
        padding: 4px;
        width: 80%;
        min-height: 60%;
      }
    }

    &-button {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;

      .confirm,
      .cancel {
        padding: 15px 15%;
        border: none;

        &.cancel {
          background: #d3d3d3;
        }

        &.confirm {
          background: #808080;
        }
      }
    }
  }

  &-exit {
    width: 35%;
    height: 35%;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;

    &-content {
      text-align: center;
      min-height: 60%;
      align-content: center;
    }

    &-warning {
      color: red;
    }

    &-button {
      color: red;

      &-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
      }

      &-yes {
        padding: 15px 15%;
        border: none;
        background: #808080;
      }

      &-cancel {
        padding: 15px 15%;
        border: none;
        background: #d3d3d3;
      }
    }
  }
}
