@import 'shared/styles/app';

.surveysscreen {
  background-color: var(--clam-shell);
  width: 100%;
  height: 100%;
  display: flex;

  &-SLKModal {
    padding: 0 100px !important;
    overflow: hidden;

    &-input {
      margin-top: 20px;
    }

    &-errortext {
      color: red;
      margin-top: 10px;
    }
  }

  &-content {
    width: 100%;
    padding: 16px 30px;

    p {
      font-weight: bold;
      margin-bottom: 12px;
      font-size: 18px;
    }
  }

  &-row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;

    &-form {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;

      :global(.MuiSelect-select) {
        font-size: 18px;
        font-weight: bold;
        padding: 20px;
      }
    }

    &-syncButton {
      margin-left: 10px;

      @media (max-width: 600px) {
        // Remove margin for iPad Mini
        margin-left: 0;
      }

      button {
        display: flex;
        color: var(--black);
        padding: 15px;
        min-width: 125px;
      }

      &--loading {
        svg[class^='Icon_icon__'] {
          animation: spin-animation 1s infinite;
        }
      }
    }
  }

  &-row2 {
    height: calc(100% - 240px);

    &-table {
      & div {
        border: none;
        font-size: $baseFontFamily;
        font-weight: bold;
      }

      .surveyStatusIcon {
        &--loading {
          animation: spin-animation 1s infinite;
        }
      }

      :global {
        .MuiDataGrid-root {
          overflow: hidden;
          border: 3px solid var(--black);
          border-radius: 30px 20px 22px 25px 30px 24px 24px 20px;

          .MuiDataGrid-columnHeaders {
            color: var(--white);
            background-color: var(--breaker-bay);
            border-left: none;
            border-right: none;
            border-bottom: 3px solid var(--black);

            .MuiDataGrid-columnSeparator {
              display: none;
            }
          }

          .MuiDataGrid-row {
            &:nth-of-type(odd) {
              background-color: rgb(161 102 76 / 20%);
            }
          }

          .MuiDataGrid-cell {
            border-bottom: 2px solid var(--black);
          }
        }
      }

      &-placeholder {
        border-bottom: none !important;
      }
    }
  }

  &-row3 {
    position: absolute;
    bottom: 15px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px 40px;

    &-icon {
      display: flex;
      flex-wrap: nowrap;
      gap: 14px;
      align-items: center;

      svg {
        flex: 0 0 30px;
      }

      span {
        font-size: $baseFontSize;
        font-weight: normal;
      }
    }

    &-button {
      display: flex;
      flex-wrap: nowrap;
      gap: 14px;
      align-items: center;
      width: 230px;
      height: 50px;

      span {
        font-size: $baseFontSize;
        font-weight: normal;
      }
    }
  }
}
