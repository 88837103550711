@import 'shared/styles/app';

.border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

  &-disabled {
    opacity: 0.7;
  }

  &-transform {
    transform: scale(-1, -1);
  }

  &-dropShadow {
    z-index: -1;

    svg {
      filter: drop-shadow(0 6px 0 rgb(51 51 51 / 20%));
    }

    &.border-transform {
      will-change: transform;

      svg {
        filter: drop-shadow(0 -6px 0 rgb(51 51 51 / 20%));
      }
    }
  }

  &-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    filter: none !important;
    z-index: 1;
  }
}
