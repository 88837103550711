@import 'shared/styles/app';

.enterparticipantsslkscreen {
  background-color: var(--gray-suit);
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  display: flex;

  &-content {
    margin-top: 10%;
    margin-bottom: 5%;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: space-between;

    &-inputcontainer {
      width: 80%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-input {
        width: 45%;
      }

      &-errortext {
        color: red;
        margin-top: 10px;
      }
    }

    &-foundedtextcontainer {
      margin-top: 5%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title {
        margin-bottom: 20px;
      }
    }

    &-buttons {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
    }
  }
}
