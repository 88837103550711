@import 'shared/styles/app';

.section-progress {
  &-titles {
    max-width: 600px;
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &--few-options {
    --option-width: 100%;
  }

  &--many-options {
    --option-width: 60%;
  }
}
