@import 'shared/styles/app';

.feedback {
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  margin: auto;
  border: 0;
  padding: 0 50px;

  &-icon {
    all: unset;
    cursor: pointer;
    aspect-ratio: 1;

    &--dimmer {
      opacity: 0.5;
    }

    &--circle {
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }

    &--color svg {
      will-change: filter;
      filter: drop-shadow(10px 0 0 white) drop-shadow(-10px 0 0 white)
        drop-shadow(0 10px 0 white) drop-shadow(0 -10px 0 white)
        drop-shadow(5px 7px 4px rgb(0 0 0 / 40%));
    }

    &--scale {
      width: 100%;
      height: 100%;

      svg {
        width: 80%;
        height: 80%;
      }
    }
  }
}
