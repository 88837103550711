@import 'shared/styles/app';

.modal {
  text-align: center;
  overflow: visible;

  :global {
    .MuiPaper-root {
      position: relative;
      overflow: visible;

      :root {
        --dialog-content-margin-top: 0px; /* Default value */
      }

      .MuiDialogContent-root {
        margin: 20px 5px var(--dialog-content-margin-top);
        overflow-x: hidden;

        &:has(div[class^='FollowUpBody']) {
          overflow: hidden;
          margin: unset;
          margin-bottom: -10dvh;
        }
      }

      div[class^='MultipleChoice_multiplechoice__wrapper__'] {
        margin-top: 0 !important;
        position: unset;
      }
    }
  }

  &-paper {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  &-title {
    background-color: transparent !important;
    z-index: 998;

    span {
      font-size: 28px;
      font-weight: bold;
    }
  }

  &-closeButton:global(.MuiButtonBase-root) {
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 999;
  }

  &-closeButton--near:global(.MuiButtonBase-root) {
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 1000;
  }

  &-buttons {
    background-color: transparent;
    display: flex;
    padding: 50px 0 35px !important;
    justify-content: center !important;
  }

  &-no-actions {
    background-color: transparent;
    padding: 15px !important;
  }
}

// Footer Modal Style
:global {
  .footer-modal {
    .MuiPaper-root {
      background-color: var(--black);
      padding: 50px;

      h2 {
        font-size: 35px;
        line-height: 1.5;
        letter-spacing: 0.0125em;
        margin-top: 0;
        text-align: center;
      }

      .MuiDialogActions-root {
        padding-top: 0 !important;
      }
    }

    &__buttons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;

      button {
        color: var(--contessa);
        max-width: 230px;
        height: 100%;
      }
    }

    &__resumeButton {
      height: 100%;
      width: 230px;
      margin: 15px auto auto;
    }

    &__skipButtons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      button {
        width: 250px;
        color: var(--contessa);
      }
    }
  }

  .dashboardscreen-dailyFeedbackModal {
    .MuiDialogContent-root {
      margin: 30px 25px 0 !important;
      padding-top: 0;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgb(51 51 51 / 20%);
    }

    ::-webkit-scrollbar {
      margin-right: 10px;
      width: 10px;
      background-color: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--cape-palliser) !important;
    }
  }

  .SLKUpdateModal {
    padding: 30px;
    width: 100%;
  }
}
