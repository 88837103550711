/* Colour Palette */

:root {
  /*
    The idea is that these colours will no longer
    be preset in the future, and themed variables can
    be set in Umbraco. Otherwise, these do not need to
    be CSS-style variables.
  */

  // Basic Colors
  --white: #ffffff;
  --black: #222222;
  --botticelli: #c2cfdf;
  --breaker-bay: #55968c;
  --butterfly-bush: #5c5182;
  --cape-palliser: #a1664c;
  --chestnut-rose: #d35555;
  --contessa: #c57861;
  --crimson: #da1414;
  --green-smoke: #8fa26f;
  --pot-pourri: #f2e6de;
  --paris-white: #cce0dd;
  --santa-fe: #b77548;
  --tundora: #404040;

  // Drink Colors
  --standard-drink: #f28e1c;
  --fizzy-drink: #639bcf;

  // Extended colours
  --athens-gray: #dfe2e8;
  --bone: #e2c8b6;
  --blue-haze: #d9cce0;
  --clam-shell: #d9c2b7;
  --gray-nurse: #dee3e0;
  --gray-suit: #beb9cd;
  --green-white: #eef1e9;
  --hampton: #e5cfab;
  --mine-shaft: #222;
  --spring-wood: #f7f3f0;
  --swiss-coffee: #e2d8d7;

  /* Default primary and secondary colours */
  --theme-primary-colour: #b58562;
}
