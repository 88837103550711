@import 'shared/styles/app';

.alert {
  padding: 7px 15px !important;
  font-family: $baseFontFamily !important;
  position: relative;
  z-index: 0;

  &-popup {
    position: absolute;
    z-index: 1000;
  }

  &-inline {
    display: inline-flex !important;
  }

  & > * {
    display: flex;
    align-items: center;
    padding: 0 !important;
    line-height: 20px;
    font-size: 12px;
    font-weight: bold;
  }
}
